/* eslint-disable @next/next/no-img-element */
import { swiperConfig } from '@/utils/SwiperConfig';
import React, { useEffect } from 'react'
import useContentGlobal from '@/hooks/useContentGlobal';
import Image from 'next/image';
import Link from 'next/link';



export const PromotionHome = ({ listPromotion }: { listPromotion: any }) => {
  const { getValueByKey } = useContentGlobal();

  useEffect(() => {
    swiperConfig(".km-m-slider", {
      speed: 600,
      initialSlide: 0,
      centeredSlides: false,
      loop: false,
      spaceBetween: 0,
      effect: "slide",
      slidesPerView: "auto",
    }, "bullets");
  }, [])

  if (listPromotion?.listPromotion?.length < 1) return null
  return (
    <section className="sec-km">
      {/* km sc-pd */}
      <div className="km ht">
        <div className="container">
          <div className="km-m" data-aos="fade-up">
            <div className="sec-heading">
              <h2 className="heading">{getValueByKey("Discounts") ?? "Khuyến mãi"}</h2>
              {/* feedback 200 priority 3 */}
            </div>
            <div className="km-m-slider relative">
              <div className="swiper-container">
                <div className="swiper rows">
                  <div className="swiper-wrapper">
                    {listPromotion?.listPromotion?.map((item: any, index: number) => {
                      return (
                        <div className="swiper-slide col col-4" key={item?.banner_id}>
                          <Link href={item?.link} className="km-bn-img">
                            <Image width={380} height={213} sizes="(max-width: 426px) 150px, (max-width: 768px) 250px, 100vw" loading={index > 2 ? "lazy" : "eager"} src={item?.media || ''} alt="banner-promotion" className='!top-1/2 !left-1/2' />
                          </Link>
                        </div>)
                    })}
                  </div>
                </div>
              </div>
              <div className="swiper-pagination --second"></div>
              <div className="button-swiper">
                <div className="arrow-btn-prev swiper-prev"><i className="far fa-chevron-left"></i></div>
                <div className="arrow-btn-next swiper-next"><i className="far fa-chevron-right"></i></div>
              </div>
            </div>
            <a href={'/chuong-trinh-khuyen-mai/'} className="km-m-btn btn btn--pri mg-auto">{getValueByKey("All_Discounts_And_Promotions") ?? "Tất cả ưu đãi"}</a>
          </div>
        </div>
      </div>
    </section>
  )
}