/* eslint-disable @next/next/no-img-element */
import { convertDate, toggleLanguage } from "@/helper";
import { useSavaSessionKey } from "@/hooks";
import useContentGlobal from "@/hooks/useContentGlobal";
import { useWindowSize } from "@/hooks/userWindowSize";
import { listCinema, sendLog } from "@/services";
import { SortCinema } from "@/utils/sortCinema";
import { ConfigProvider, Select } from "antd";
import Link from "next/link";
import router from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { LoadingCustom } from "../common/LoadingCustom";
type NavigateProps = {
  listCinemas: TCinima[],
  listBaseMovies:  TMovie[] | undefined
}
type DefaultItemType = {
  value: string,
  label: string,
  type: string,
  stepValue: number
}
type NavigateItemType = {
  id: string,
  defaultValue: DefaultItemType,
  options: DefaultItemType[]
}
export const NavigateBooking: React.FC<NavigateProps> = ({ listCinemas, listBaseMovies }) => {
  const { getValueByKey, status } = useContentGlobal();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const initialValue = {
    idTheater: {
      value: "0",
      label: `1. ${getValueByKey("Select_Theater") ?? "Chọn rạp"}`,
      type: "idTheater",
      stepValue: 0
    }, idMovie: {
      value: "0",
      label: `2. ${getValueByKey("Select_Movie") ?? "Chọn phim"}`,
      type: "idMovie",
      stepValue: 1
    }, date: {
      value: "0",
      label: `3. ${getValueByKey("Select_Date") ?? "Chọn ngày"}`,
      type: "date",
      stepValue: 2
    }, time: {
      value: "0",
      label: `4. ${getValueByKey("Select_Screening") ?? "Chọn suất"}`,
      type: "time",
      stepValue: 3
    }
  }
  const [data, setData] = useState<any>(initialValue)
  const [showTime, setShowTime] = useState<any>()
  const { session_key } = useSavaSessionKey();
  const [step, setStep] = useState(0)
  const { isMobile } = useWindowSize()
  const [dataCinemas, setDataCinemas] = useState<any>([])
  const [dataMovies, setDataMovies] = useState<any>([])
  const [error, setError] = useState<any>()
  const ref = useRef(new Array(data.length))
  const [isSorted, setIsSorted] = useState(false)
  useQuery(
    ['cinema_book'],
    async () => {
      const resJson = await listCinema.getListCinema()
      return resJson
    },
    {
      select(data: any) {
        return data?.cinemas
      },
      onSuccess(data) {
        SortCinema(data, setDataCinemas, setError)
      },
      enabled: !listCinemas || listCinemas?.length < 1
    }
  )


  const fetchDataShowTime = async (id_Movie?: string, id_Area?: string, id_Server?: string, date?: string, id_MovieTheater?: string) => {
    setIsLoading(true)
    try {
      const res = await fetch(
        `/api/showTime?id_Movie=${id_Movie}&id_Area=${id_Area}&id_Server=${id_Server}&date=${date}&id_MovieTheater=${id_MovieTheater}`
      )
        .then((res) => res.json())
        .catch((err) => {
          sendLog({
            action: 'fetch_data_showtime',
            page_name: 'MovieBox',
            page_url: router.asPath,
            session_key: session_key,
            status: 'fail',
            error_message: JSON.stringify(err),
            information: 'Fetch data showtime fail',
            request_json: JSON.stringify({
              id_Movie: id_Movie,
              id_Area: id_Area,
              id_Server: id_Server,
              date: date,
              id_MovieTheater: id_MovieTheater
            })
          })
        })
        const listMovie: TMovie[] = (res?.data)?.filter((movie:any) => movie?.schedule?.length > 0)?.sort((a: TMovie, b: TMovie) => {
          if(a?.sortorder && b?.sortorder){
            return Number(a?.sortorder) - Number(b?.sortorder)
          }
          return 0;
        })
        const listDataMovies = listBaseMovies?.reduce((acc, movie)=> {
          const movieHasSchedule = listMovie?.find((item) => item?.id == movie?.id)
          if(movieHasSchedule){
            acc.push(movieHasSchedule)
          }
          return acc
        }, [] as TMovie[])
        setDataMovies(listDataMovies)
    } catch (err) {
      sendLog({
        action: 'fetch_data_showtime',
        page_name: 'MovieBox',
        page_url: router.asPath,
        session_key: session_key,
        status: 'fail',
        error_message: JSON.stringify(err),
        information: 'Fetch data showtime fail',
        request_json: JSON.stringify({
          id_Movie: id_Movie,
          id_Area: id_Area,
          id_Server: id_Server,
          date: date,
          id_MovieTheater: id_MovieTheater
        })
      })
    } finally {
      setIsLoading(false)
    }
  }


  const handleGetName = (cinema: TCinima) => {
    const alias = toggleLanguage(cinema, 'alias')
    if (alias) return alias
    return toggleLanguage(cinema, 'name')
  }

  const listAction: NavigateItemType[] = [
    {
      id: 'idTheater',
      defaultValue: {
        value: '0',
        label: `1. ${getValueByKey("Select_Theater") ?? "Chọn rạp"}`,
        type: 'idTheater',
        stepValue: 0
      },
      options: dataCinemas?.map((item: any) => ({
        value: item?.id,
        label: handleGetName(item),
        type: 'idTheater',
        stepValue: 0
      }))
    },
    {
      id: 'idMovie',
      defaultValue: {
        value: '0',
        label: `2. ${getValueByKey("Select_Movie") ?? "Chọn phim"}`,
        type: 'idMovie',
        stepValue: 1
      },
      options: dataMovies?.map((item: any) => ({
        value: item?.id,
        label: handleGetName(item),
        type: 'idMovie',
        stepValue: 1
      }))
    },
    {
      id: 'date',
      defaultValue: {
        value: '0',
        label: `3. ${getValueByKey("Select_Date") ?? "Chọn ngày"}`,
        type: 'date',
        stepValue: 2
      },
      options: showTime?.map((item: any) => {
        const label = convertDate(item?.date)
        return {
          value: item?.date,
          label: `${toggleLanguage(label, 'label')}, ${label?.value}`,
          type: 'date',
          stepValue: 2
        }
      })
    },
    {
      id: 'time',
      defaultValue: {
        value: '0',
        label: `4. ${getValueByKey("Select_Screening") ?? "Chọn suất"}`,
        type: 'time',
        stepValue: 3
      },
      options: showTime
        ?.filter((el: any) => el.date == data?.date)?.[0]
        ?.times?.map((item: any) => {
          const labelMovie = dataMovies.find((item: any) => item?.id == data?.idMovie)
          return {
            value: item?.showtime_id,
            label: `${item?.time} - ${toggleLanguage(labelMovie, 'formats_name') ?? ""} ${toggleLanguage(item, 'room_type_name')}`,
            type: 'time',
            stepValue: 3
          }
        })
    }
  ]

  const handleFetchShowtimes = (values: DefaultItemType) => {
    const theaterData = dataCinemas.find((el: TCinima) => el?.id == values?.value)
    const currentDate = new Date().getDate()?.toString()
    fetchDataShowTime("0", theaterData?.area_id, theaterData?.id_server, currentDate, theaterData?.id)
  }

  useEffect(() => {
    if (status !== "success") return
    if (error == 0) {
      setData({
        ...initialValue, idTheater: {
          value: dataCinemas?.[0]?.id,
          label: handleGetName(dataCinemas?.[0]),
          type: "idTheater",
          stepValue: 0
        }
      })
      setStep(1)
      handleFetchShowtimes({
        value: dataCinemas?.[0]?.id,
        label: handleGetName(dataCinemas?.[0]),
        type: "idTheater",
        stepValue: 0
      })
      setData((prev: any) => ({ ...prev, idTheater: dataCinemas?.[0]?.id }))
    } else {
      setData(initialValue)
    }
  }, [error, dataCinemas, status])

  const getListDefaultValue = (value: number) => {
    const defaultValues = listAction.reduce((acc: any, cur) => {
      if (cur.defaultValue.stepValue > value) {
        acc[cur.id] = cur.defaultValue
      }
      return acc
    }, {})
    return defaultValues
  }

  const handleHidden = (value: number) => {
    const nextElement = document.querySelectorAll(".select-location-second.navigate-filter-item") as NodeListOf<HTMLDivElement>
    nextElement.forEach((item, index) => {
      if (index == value) item.style.display = "block"
      else if (index > value) {
        item.style.display = "none"
      }
    })
  }

  const handleSelectChange = async (e: DefaultItemType, values: DefaultItemType) => {
    isMobile && handleHidden(values.stepValue + 1)
    ref.current?.[values.stepValue + 1]?.focus()
    try {
      if (values.stepValue == 0) {
        handleFetchShowtimes(values)
      }
      if (values.stepValue == 1) {
        const movie = dataMovies?.find((movie: any) => movie.id == e)
        setShowTime(movie?.schedule)
      }
      setStep(values.stepValue + 1)
      const listDefault = getListDefaultValue(values.stepValue)
      setData((prev: any) => ({ ...prev, [values.type]: e, ...listDefault }))
    } catch (error) {
      console.error('Error during redirection:', error)
    }
  }
  const renderContent = (content: NavigateItemType, index: number) => {
    const id = content.id
    return <Select aria-label={content?.defaultValue?.label}
    // return status == "error" ? <></> : <Select aria-label={content?.defaultValue?.label}
    onFocus={()=>{
      if(index==0 && listCinemas.length && !isSorted) {
        SortCinema(listCinemas, setDataCinemas, setError)
        setIsSorted(true)
      }
    }}
      key={isMobile ? content.id : index}
      popupMatchSelectWidth={isMobile}
      value={data[id]}
      className="area-theater"
      onChange={(e, value) => handleSelectChange(e, value as DefaultItemType)}
      options={content.options}
      notFoundContent={isLoading ? <div className="h-[8rem]"><LoadingCustom /></div> : <div className="movies-rp-noti navigate-notfound">
        <div className="dark-image"><img src="/assets/images/movie-updating.png" alt="" /></div>
        {getValueByKey("Screening_Empty") ?? "Không có dữ liệu"}
      </div>}
      showAction={["click", "focus"]}
      ref={node => ref.current[index] = node}
    />
  }
  const handleRedirect = () => {
    const theaterData = dataCinemas?.find((el: TCinima) => el.id == data.idTheater)
    if (!theaterData || typeof data.idTheater != "string") return ""
    if (!data.idMovie || typeof data.idMovie != "string") return `book-tickets/${theaterData.id}`
    const link = `movie/${data.idMovie}?id=${theaterData?.area_id}&id_sv=${theaterData?.id_server}${data?.time && typeof data?.time == "string" ? '&show_time=' + data?.time : ''}${data?.date && typeof data?.date == "string" ? '&date=' + data?.date : ''}`
    return link
  }

  return <ConfigProvider
    theme={{
      components: {
        Select: {
          optionSelectedBg: "#F3EA28"
        },
      }
    }}>
    <section className="container">
      <div className="navigate-wrap" data-aos="fade-up">
        <div className="heading max-w-[160px] whitespace-nowrap">
          <h1 suppressHydrationWarning>{getValueByKey("Quick_Book") ?? "Đặt vé nhanh"}</h1>
        </div>
        <div className="navigate-filter">
          {listAction.map((item, key) => {
            return <div key={key} className={`select-location-second navigate-filter-item ${step == key ? "active" : ""} ${step > key ? "isActive" : ""}`}>
              {renderContent(item, key)}
            </div>
          })}
          <div className={`navigate-filter-btn ${step == 4 ? "active" : ""}`}>
            <Link href={`/${handleRedirect()}`} className={`whitespace-nowrap btn btn--second ${typeof data.idTheater != "string" ? "preventClick" : ""}`}>
              {getValueByKey("Buy_Now") ?? "Đặt ngay"}
            </Link>
          </div>
        </div>
      </div>
    </section>
  </ConfigProvider>
}
